import BRAND_LOGO from '../../assets/coopImages/brand_logo.png';
import BRAND_LOGO_WHITE from '../../assets/coopImages/brand_logo_white.png';

export const BRAND_IMAGE = {
    BRAND_LOGO,
    BRAND_LOGO_WHITE,
}

export const BRAND_NAME = 'Holo';

export const FEATURE = [
    {
        name: 'Manage Parcel',
        role: 'manageParcel'
    },
    {
        name: 'Manage Express',
        role: 'manageExpress'
    },
    {
        name: 'Manage Interisland',
        role: 'manageInterisland'
    },
    {
        name: 'Manage Customers',
        role: 'manageCustomers'
    },
    {
        name: 'Manage Users',
        role: 'manageUsers'
    },
    {
        name: 'Manage Drivers',
        role: 'manageDrivers'
    },
    {
        name: 'Manage Rate',
        role: 'manageRate'
    },
    {
        name: 'Manage Wallet',
        role: 'manageWallet'
    },
    {
        name: 'Manage Billing',
        role: 'manageBilling'
    },
    {
        name: 'Manage Role',
        role: 'manageRole'
    },
    {
        name: 'Manage Warehouse',
        role: 'manageWarehouse'
    },
]

export const PERMISSIONS = [
    {
        name: 'Create',
        action: 'canCreate'
    },
    {
        name: 'Edit',
        action: 'canEdit'
    },
    {
        name: 'List',
        action: 'canRead'
    },
    {
        name: 'Delete',
        action: 'canDelete'
    }
]